// src/components/AboutUs.js

import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Radianture</title>
        <meta
          name="description"
          content="Discover the wide range of services offered by Radianture."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.paper",
          color: "text.primary",
          py: 8,
        }}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" paragraph>
            At Radianture, we are a global creative, tech, and production
            agency, transforming how the world experiences your products through
            compelling visual effects and animation that bring your ideas to
            life.
          </Typography>
          <Typography variant="body1" paragraph>
            We redefine efficiency for businesses, fostering innovation through
            the synergy of expert knowledge and smart automation. Our tailored
            solutions, crafted by subject matter experts, empower clients across
            dynamic industries such as retail, fashion, media, entertainment,
            manufacturing, travel, leisure, software and high-tech. By
            optimizing processes and enhancing performance, we ensure our
            services align seamlessly with your budgetary constraints.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default AboutUs;
