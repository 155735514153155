import { useEffect } from "react";

const useDisableRightClick = () => {
  useEffect(() => {
    const disableContextMenu = (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault();
      }
    };

    // Add event listener to the document
    document.addEventListener("contextmenu", disableContextMenu);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);
};

export default useDisableRightClick;
