import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async"; // Import HelmetProvider
import theme from "./theme";
import Header from "./components/Header";
import Home from "./pages/Home";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import WhatWeDo from "./components/WhatWeDo";
import CgiImagery from "./components/cgi-imgery";
import Animation from "./components/animation";
import VFX from "./components/vfx";
import GameArt from "./components/game-art";
import Ar from "./components/ar";
import RealTimeConfigurator from "./components/RealTimeConfigurator";
import PrivatePortfolio from "./components/PrivatePortfolio";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useDisableRightClick from "./hooks/useDisableRightClick";

export const routes = [
  { path: "/", component: Home },
  { path: "/services", component: Services },
  { path: "/services/cg-imagery", component: CgiImagery },
  { path: "/services/animation", component: Animation },
  { path: "/services/game-art", component: GameArt },
  { path: "/services/vfx", component: VFX },
  { path: "/services/ar-vr", component: Ar },
  { path: "/private-portfolio/:linkCode", component: PrivatePortfolio },
  { path: "/private-portfolio/", component: PrivatePortfolio },
  { path: "/services/real-time-configurator", component: RealTimeConfigurator },
  { path: "/portfolio", component: Portfolio },
  { path: "/contact", component: Contact },
  { path: "/what-we-do", component: WhatWeDo },
];

const OnRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  return null;
};

const App = () => {
  useDisableRightClick();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Router>
          <Header />
          <OnRouteChange />
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
          <Footer />
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
