// src/components/OurValues.js

import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

import clients from "../assets/values/Client-First.png";
import Excellance from "../assets/values/Excellance.png";
import Experiance from "../assets/values/Experiance.png";
import Integrity from "../assets/values/Integrity.png";

const values = [
  {
    title: "Excellence",
    image: Excellance,
    description:
      "Striving for excellence is a continual voyage, marked by relentless innovation and unwavering commitment to the highest benchmarks",
  },
  {
    title: "Experience",
    image: Experiance,
    description:
      "We craft impactful experiences for our clients, teams and partners, fostering trust and fortifying relationships to amplify our community impact",
  },
  {
    title: "Integrity",
    image: Integrity,
    description:
      "We conduct our business with honesty and transparency, building trust with our clients, partners and team members.",
  },
  {
    title: "Client-First",
    image: clients,
    description:
      "We are dedicated to provide exceptional service and creating value that helps client achieve their goals",
  },
];

const OurValues = () => {
  return (
    <Box sx={{ bgcolor: "background.paper", color: "text.primary", py: 8 }}>
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Our Values
        </Typography>
        <br />
        <Grid container spacing={4}>
          {values.map((value, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  bgcolor: "background.default",
                  boxShadow: 3,
                }}
              >
                <CardContent>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={value.image}
                      alt="Values"
                      style={{ width: "100px", marginBottom: "10px" }}
                    />
                  </div>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    align="center"
                  >
                    {value.title}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {value.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurValues;
