import React, { useState, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import heroVideo from "../assets/RadiantureShowreel.mp4";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { handleContextMenu } from "../utils";

const Hero = () => {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  const handleScrollDown = () => {
    const scrollAmount = document.documentElement.scrollHeight * 0.23; // 10% of the total height
    window.scrollBy({ top: scrollAmount, behavior: "smooth" });
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "95vh",
          width: "100vw", // Ensures full screen width
          overflow: "hidden",
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          loop
          muted={isMuted}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "110%",
            objectFit: "cover",
            zIndex: -1,
          }}
          onContextMenu={handleContextMenu}
        >
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <IconButton
          onClick={toggleMute}
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            color: "white",
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            textAlign: "left",
            color: "white",
            width: "100%",
            margin: 0,
            background:
              "linear-gradient(0deg, rgb(15 15 15 / 19%) 0%, rgb(0 0 0) 0%, rgba(255, 255, 255, 0) 60%)",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              mb: 2,
              padding: "10px",
              marginLeft: "9%",
            }}
          >
            Empowering Vision, <br />
            Elevating Reality <br />
            <KeyboardDoubleArrowDownIcon
              sx={{ fontSize: "60px", cursor: "pointer" }}
              onClick={handleScrollDown}
            />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
