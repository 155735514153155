import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/RadiantureLogo.png";
import symbol from "../assets/RadiantureMono.png";

const NavButton = ({ to, children }) => {
  return (
    <Button
      color="inherit"
      component={RouterLink}
      to={to}
      sx={{
        px: 0, // Reduce the left and right padding
        mx: 2, // Reduce the left and right margin
        minWidth: 0,
        // border: "1px solid",
        position: "relative",
        padding: "0",
        lineHeight: 1.2,
        backgroundColor: "transparent",
        ":hover": {
          backgroundColor: "#fff",
          color: "text.primary",
        },
        "::after": {
          content: '""',
          position: "absolute",
          width: "0%",
          height: "2px",
          bottom: 0,
          left: 0,
          backgroundColor: "text.primary",
          transition: "width 0.3s ease-in-out",
        },
        ":hover::after": {
          width: "100%",
        },
      }}
    >
      {children}
    </Button>
  );
};

const Header = () => {
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [logoSlideIn, setLogoSlideIn] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    const isHome = window?.location?.pathname === "/" ? true : false;
    const scrollPercentage =
      (window.scrollY / document.documentElement.scrollHeight) * 100;
    if (scrollPercentage > 10) {
      setLogoSlideIn(true);
    } else {
      setLogoSlideIn(false);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [location]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 2,
        }}
      >
        <img
          src={logo}
          alt="Radianture Logo"
          style={{ height: 40, marginRight: 16 }}
        />
      </Box>
      <List>
        <ListItem button component={RouterLink} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={RouterLink} to="/services">
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button component={RouterLink} to="/portfolio">
          <ListItemText primary="Portfolio" />
        </ListItem>
        <ListItem button component={RouterLink} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" sx={{ paddingLeft: "0" }}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              flexGrow: 1,
              cursor: "default",
            }}
          >
            <Grid
              component={RouterLink}
              to={"/"}
              sx={{
                marginRight: "10px",
                marginLeft: "-25px",
                paddingLeft: "25px",
                zIndex: 9999,
                position: "absolute",
                background: "#fff",
                cursor: "pointer",
              }}
            >
              <img
                src={symbol}
                alt="Radianture"
                style={{
                  width: "35px",
                }}
                onMouseEnter={() => {
                  setLogoSlideIn(false);
                }}
                onMouseOut={() => {
                  handleScroll();
                }}
              />
            </Grid>
            <Grid
              sx={{
                height: "35px",
                display: "flex",
                alignItems: "end",
                cursor: "pointer",
              }}
            >
              <img
                src={logo}
                alt="Radianture Logo"
                style={{
                  width: "140px",
                  height: "23px",
                  marginLeft: "45px",
                  transition: "transform 0.5s ease-in-out",
                  transform: logoSlideIn
                    ? "translateX(-150px)"
                    : "translateX(0)",
                }}
              />
            </Grid>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block", cursor: "pointer" } }}>
            <NavButton to="/">Home</NavButton>
            <NavButton to="/what-we-do">What we do</NavButton>
            <NavButton to="/services">Services</NavButton>
            <NavButton to="/portfolio">Portfolio</NavButton>
            <NavButton to="/contact">Contact</NavButton>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Header;
