// src/components/Footer.js

import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { LinkedIn, Instagram } from "@mui/icons-material";
import XIcon from "../assets/icons8-twitterx-24.png";
import TMLogo from "../assets/Radianture_Logo_40px_TM.png";

const Footer = () => (
  <Box
    sx={{
      backgroundColor: "background.default",
      color: "text.primary",
      py: 4,
    }}
  >
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            About Radianture&trade;
          </Typography>
          <Typography variant="body2">
            At Radianture, we redefine efficiency for businesses, fostering
            innovation through the synergy of expert knowledge and smart
            automation.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Link href="/" color="inherit" underline="hover">
            Home
          </Link>
          <br />
          <Link href="/services" color="inherit" underline="hover">
            Services
          </Link>
          <br />
          {/* <Link href="/portfolio" color="inherit" underline="hover">
            Portfolio
          </Link> */}
          {/* <br /> */}
          <Link href="/contact" color="inherit" underline="hover">
            Contact
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          <Box>
            <IconButton
              color="inherit"
              href="https://x.com/radianture?s=11&t=fnO94Bm8LiiVuduZwCLRzw"
              target="_blank"
            >
              <img src={XIcon} alt="Twitter" />
            </IconButton>
            <IconButton
              color="inherit"
              href="https://www.linkedin.com/company/radianture/"
              target="_blank"
            >
              <LinkedIn />
            </IconButton>
            <IconButton
              color="inherit"
              href="https://www.instagram.com/radianture?igsh=dWJoYnMydTczY2hp&utm_source=qr"
              target="_blank"
            >
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Box
            component="img"
            sx={{
              width: "200px",
              height: "auto",
            }}
            alt="Trademark"
            src={TMLogo}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Radianture. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Footer;
