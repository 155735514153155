// src/components/OurValues.js

import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

import cost from "../assets/benefits/Cost-efficient_60px.png";
import convo from "../assets/benefits/Conversion_Boost_60px.png";
import quality from "../assets/benefits/Exquisit_quality_60px.png";
import omni from "../assets/benefits/Omnipresent_60px.png";

const values = [
  {
    title: "Exquisite quality",
    image: quality,
    description: "Ultra-realistic depiction of the product",
  },
  {
    title: "Omnipresent",
    image: omni,
    description:
      "Effortlessly personalize your masterpiece on any device, wherever you are",
  },
  {
    title: "Conversion boost",
    image: convo,
    description:
      "Superior customer experience with significant increase in conversion rate",
  },
  {
    title: "Cost-efficient",
    image: cost,
    description: "Exceptional value for money with premium results",
  },
];

const Benefits = () => {
  return (
    <Box
      sx={{
        color: "text.primary",
        py: 8,
        borderRadius: 4,
      }}
    >
      <Container>
        <br />
        <Grid container spacing={4}>
          {values.map((value, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  bgcolor: "background.paper",
                  boxShadow: 3,
                }}
              >
                <CardContent>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img
                      alt="Benefits"
                      src={value.image}
                      style={{ width: "100px", marginBottom: "10px" }}
                    />
                  </div>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    align="center"
                  >
                    {value.title}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {value.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Benefits;
