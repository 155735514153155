import React, { useState, useRef } from "react";
import { Container, Grid, Box, Typography, IconButton } from "@mui/material";
import { handleContextMenu } from "../utils";
import Gaming_Video from "../assets/Gaming_Video.mp4";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Helmet } from "react-helmet-async";

const GameArt = () => {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <>
      <Helmet>
        <title>Game Art</title>
        <meta
          name="description"
          content="Discover the wide range of services offered by Radianture."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                Game Art
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At Radianture, our gaming services redefine interactive
                entertainment by combining advanced technology with creative
                innovation to deliver immersive gaming experiences. Recognizing
                the ever-evolving nature of the gaming industry, we strive to
                push the boundaries of what's possible, integrating cutting-edge
                graphics, engaging narratives, and intuitive gameplay mechanics
                to captivate players from the first moment and keep them coming
                back. From concept development to full-scale production, our
                skilled team of designers, developers, and artists
                collaboratively bring unique game concepts to life, meticulously
                crafting every detail to provide an exceptional player
                experience. We pride ourselves on creating diverse gaming
                environments that cater to a wide range of genres and player
                preferences, whether it’s a fast-paced action game or a richly
                detailed RPG. Quality is at the heart of everything we do,
                evident in the high standards of excellence seen in the assets
                we deliver for various games. Partner with Radianture to elevate
                your game development projects and bring your creative visions
                to life with unparalleled quality and innovation.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: -1,
                  }}
                  onContextMenu={handleContextMenu}
                >
                  <source src={Gaming_Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <IconButton
                  onClick={toggleMute}
                  sx={{
                    position: "absolute",
                    top: 20,
                    right: 20,
                    color: "white",
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default GameArt;
