// src/components/WhatWeDo.js

import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import service3 from "../assets/What_we_do_1K.jpg"; // Replace with the path to your image
import OurValues from "./Values";
import { Helmet } from "react-helmet-async";

const WhatWeDo = () => {
  return (
    <>
      <Helmet>
        <title>What we do</title>
        <meta
          name="description"
          content="Specialists in 3D Technology, Specialists in Creative Production"
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                What we do
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                As a leading global professional services firm, Radianture
                specializes in digital technology and visual content solutions,
                seamlessly blending art, technology and science to deliver
                captivating experiences for individuals, brands and agencies
                worldwide. We are committed to harnessing the transformative
                power of immersive technologies, transcending geographical
                barriers and propelling businesses into the future.
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At our core, we excel in simplifying complex communication
                challenges within science and engineering domains. With a
                passion for transforming intricate information into engaging
                visuals, we empower your users to become educated, enabled and
                excited about your products and services.
              </Typography>
              <br />
              <Typography variant="h5" component="h2" gutterBottom>
                Specialists in 3D Technology
              </Typography>
              <Typography variant="body1" paragraph>
                Our team specializes in developing cutting-edge 3D content
                creation and rendering technology. Leveraging this expertise, we
                enable companies to deliver customer-facing 3D product
                experiences with unparalleled richness, depth and detail,
                surpassing the capabilities of industry-standard tools.
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                Additionally, we create innovative solutions using unique
                3D-based technologies to streamline and scale the production of
                your global product marketing assets
              </Typography>
              <br />
              <Typography variant="h5" component="h2" gutterBottom>
                Specialists in Creative Production
              </Typography>
              <Typography variant="body1" paragraph>
                We are your dedicated product launch partner, providing
                comprehensive in-house production capabilities that span a full
                range of creative assets and consumer touchpoints. Count on our
                expertise to innovate, enhance efficiency and foster growth.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                alt="What We Do"
                src={service3}
              />
            </Grid>
          </Grid>
          <br />
        </Container>

        <OurValues />
      </Box>
    </>
  );
};

export default WhatWeDo;
