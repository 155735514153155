import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { handleContextMenu } from "../utils";
import ar_video from "../assets/AR-VR.mp4";
import { Helmet } from "react-helmet-async";

const ArVr = () => {
  return (
    <>
      <Helmet>
        <title>AR and VR</title>
        <meta
          name="description"
          content="Radianture’s AR and VR services create immersive, interactive experiences that blend reality with virtual elements, transforming how users engage with products and environments while enhancing customer experience and brand differentiation."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                AR - VR
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At Radianture, our AR and VR services provide immersive
                experiences that seamlessly blend reality with virtual elements,
                transforming user interaction with products and environments.
                Leveraging augmented reality, we bring products to life
                dynamically and engagingly, offering unparalleled detail and
                engagement that enhances customer experience and allows brands
                to stand out in a competitive market. Our custom software tools
                streamline the AR creation process by automating data
                conversion, enhancing image quality, and simplifying publishing,
                ensuring smooth and visually stunning AR deployment across
                various platforms. Whether demonstrating a new gadget,
                visualizing architectural designs, or creating interactive
                product catalogs, our AR services elevate the way customers
                interact with your brand, driving higher levels of engagement
                and conversion. Similarly, our VR services immerse users in
                captivating virtual environments, providing a unique and
                interactive way to explore and engage with your brand. By
                leveraging advanced technology and innovative design, we create
                VR simulations that are both engaging and educational, setting
                new standards for user experience. Partner with Radianture to
                elevate your brand with VR experiences that captivate, educate,
                and inspire, driving deeper understanding and appreciation of
                your offerings.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <video
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: -1,
                }}
                onContextMenu={handleContextMenu}
              >
                <source src={ar_video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ArVr;
