import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import cgi from "../assets/services/CG_Imagery_500px.jpg";
import animation from "../assets/services/Animation_300px.jpg";
import vfx from "../assets/services/VFX_500px.jpg";
import games from "../assets/services/Gaming_300px.jpg";
import ar from "../assets/services/AR_300px.jpg";
import rtc from "../assets/services/Configurator_Icon_300px.jpg";
import { Helmet } from "react-helmet-async";

const servicesList = [
  {
    title: "CG Imagery",
    image: cgi,
    rel: "cgi",
    routeTo: "/services/cg-imagery",
  },
  {
    title: "Animation",
    image: animation,
    rel: "animation",
    routeTo: "/services/animation",
  },
  {
    title: "Real-Time Configurator",
    image: rtc,
    rel: "rtc",
    routeTo: "/services/real-time-configurator",
  },
  { title: "AR - VR", image: ar, rel: "ar_vr", routeTo: "/services/ar-vr" },

  {
    title: "Game Art",
    image: games,
    rel: "game_art",
    routeTo: "/services/game-art",
  },

  { title: "VFX", image: vfx, rel: "vfx", routeTo: "/services/vfx" },
];

const ServiceCard = styled(Card)(({ theme, inview, index }) => ({
  position: "relative",
  height: 350,
  width: 350,
  backgroundSize: "contain",
  backgroundPosition: "contain",
  color: theme.palette.common.white,
  boxShadow: "none",
  display: "flex",
  alignItems: "end",
  textAlign: "center",
  cursor: "pointer",
  opacity: inview ? 1 : 0,
  transform: inview ? "translateY(0)" : "translateY(50px)",
  transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  transitionDelay: `${index * 0.1}s`, // delay each card by 100ms for staggered animation
}));

const Services = () => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the card is in view
  });

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="Discover the wide range of services offered by Radianture."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Our Services
          </Typography>
          <Typography variant="body1" paragraph>
            We integrate technology, art and science to design highly
            innovative, immersive and interconnected experiences and engaging
            content that enthrall individuals, brands and organizations.
          </Typography>
          <Grid container spacing={4} ref={ref}>
            {servicesList.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={service.title}>
                <ServiceCard
                  inview={inView}
                  index={index}
                  rel={service.rel}
                  onClick={() => {
                    navigate(service.routeTo);
                  }}
                >
                  <CardMedia
                    component="img"
                    height="100%"
                    image={service.image}
                    sx={{
                      transition: "transform 0.3s ease-in-out",
                      ":hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                  <CardContent
                    sx={{
                      width: "100%",
                      color: "#fff",
                      height: "20px",
                      opacity: "0.8",
                      position: "absolute",
                      background:
                        "linear-gradient(0deg, rgba(237, 236, 246, 0.713344712885154) 0%, rgb(68 68 68) 0%, rgba(255, 255, 255, 0) 100%)",
                    }}
                  >
                    <Typography variant="h5">{service.title}</Typography>
                  </CardContent>
                </ServiceCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Services;
