// src/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette_dark: {
    mode: "dark",
    primary: {
      main: "#bb86fc",
    },
    background: {
      default: "#121212",
      paper: "#1f1f1f",
    },
    text: {
      primary: "#e0e0e0",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#fff",
    },
    background: {
      default: "#fff",
      paper: "#f4f4f4",
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  spacing: 8,
});

export default theme;
