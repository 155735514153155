// src/components/Contact.js

import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Radianture_Location from "../assets/Radianture_Location_01.png";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact us</title>
        <meta
          name="description"
          content="Discover the wide range of services offered by Radianture."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.paper",
          color: "text.primary",
          py: 8,
        }}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            We appreciate your interest in Radianture. To discover more about
            our services, please feel free to contact us.
          </Typography>
          <Typography variant="body1" paragraph>
            Email:{" "}
            <a href="mailto:contact@radianture.com">contact@radianture.com</a>
          </Typography>
          <Box
            component="img"
            alt="Radianture Locations"
            sx={{ height: "400px" }}
            src={Radianture_Location}
          />
        </Container>
      </Box>
    </>
  );
};

export default Contact;
