// src/components/CgiImagery.js

import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async";

import image0 from "../assets/CGI_Imagery/Imagery_00.jpg";
import image1 from "../assets/CGI_Imagery/Imagery_001.jpg";
import image2 from "../assets/CGI_Imagery/Imagery_002.jpg";
import image3 from "../assets/CGI_Imagery/Imagery_003.jpg";
import image4 from "../assets/CGI_Imagery/Imagery_004.jpg";
import image5 from "../assets/CGI_Imagery/Imagery_005.jpg";
import image6 from "../assets/CGI_Imagery/Imagery_006.jpg";
import image7 from "../assets/CGI_Imagery/Imagery_007.jpg";
import image8 from "../assets/CGI_Imagery/Imagery_008.jpg";
import image9 from "../assets/CGI_Imagery/Imagery_009.jpg";
import image10 from "../assets/CGI_Imagery/Imagery_010.jpg";
import image11 from "../assets/CGI_Imagery/Imagery_011.jpg";
import image12 from "../assets/CGI_Imagery/Imagery_012.jpg";
import image13 from "../assets/CGI_Imagery/Imagery_013.jpg";
import image14 from "../assets/CGI_Imagery/Imagery_014.jpg";
import image15 from "../assets/CGI_Imagery/Imagery_015.jpg";
import image16 from "../assets/CGI_Imagery/Imagery_016.jpg";
import image17 from "../assets/CGI_Imagery/Imagery_017.jpg";
import image18 from "../assets/CGI_Imagery/Imagery_018.jpg";
import image19 from "../assets/CGI_Imagery/Imagery_019.jpg";

const images = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
]; // Array of image sources

const CgiImagery = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Helmet>
        <title>CGI Imagery</title>
        <meta
          name="description"
          content="Radianture specializes in producing hyper-realistic 3D product imagery that elevates brand presentation, drives sales, and provides efficient, high-quality visuals for diverse promotional needs across all communication channels."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                CG Imagery
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At Radianture, our 3D product imagery production excels in
                creating hyper-realistic visuals that showcase your products
                with stunning detail and precision, elevating your brand’s
                presentation and driving sales. Our sophisticated 3D
                visualization pipeline ensures the efficient and cost-effective
                production of high-resolution product images, perfect for
                deployment across all communication channels. We produce a
                diverse range of feature-rich product images designed for both
                online promotion and in-store displays, captivating and
                informing your audience to boost your market presence and sales
                performance. Our team leverages state-of-the-art technology and
                innovative techniques to push the boundaries of 3D product
                imagery, continuously refining processes to stay ahead of
                industry trends. Whether you need detailed close-ups to
                highlight product features or dynamic compositions to showcase
                products in various settings, Radianture delivers bespoke
                imagery solutions tailored to your specific needs. Partner with
                us to transform your product presentations and achieve new
                levels of visual excellence.
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12} md={6}>
              <Slider {...settings}>
                {images.map((src, index) => (
                  <Box
                    key={index}
                    component="img"
                    sx={{
                      width: "100%",
                      height: "auto",
                      boxShadow: 3,
                    }}
                    alt={`Slide ${index + 1}`}
                    src={src}
                  />
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CgiImagery;
