// src/components/CgiImagery.js

import React, { useState, useRef } from "react";
import { Container, Grid, Box, Typography, IconButton } from "@mui/material";
import { handleContextMenu } from "../utils";
import animation_video from "../assets/Product_Animation_Video.mp4";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Helmet } from "react-helmet-async";

const Animation = () => {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <>
      <Helmet>
        <title>Animation</title>
        <meta
          name="description"
          content="Radianture brings your products to life with dynamic, high-quality 3D animations that emphasize features and functionality, providing immersive experiences that engage customers and elevate product presentations across all platforms."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                Animation
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At Radianture, our 3D product animation production breathes life
                into your products with dynamic visuals that emphasize their
                features and functionality. By illustrating compelling benefits
                and use cases, our animations help customers easily understand
                and appreciate the unique value of your products. We transform
                static images into engaging animated videos that simplify
                complex offerings, ensuring your audience grasps the key
                advantages effortlessly. Our high-quality animations captivate
                and inform, leaving a lasting impression on viewers. We
                specialize in creating stunning 360 animations that showcase
                your product designs within unique environments, providing a
                comprehensive view that allows customers to explore every angle
                and detail. This immersive experience fosters a deeper
                connection with your brand, encouraging potential buyers to
                engage more thoroughly with your offerings. Whether
                demonstrating the intricate mechanics of a high-tech gadget or
                highlighting the elegant curves of a designer piece, our 3D
                animations elevate your product presentations to new heights. At
                Radianture, we utilize the latest technology and innovative
                techniques to deliver visually stunning, technically advanced
                animations that seamlessly integrate with your marketing
                strategies across all platforms. Partner with Radianture to
                transform your product presentations with animations that are as
                dynamic and compelling as the products they represent.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: -1,
                  }}
                  onContextMenu={handleContextMenu}
                >
                  <source src={animation_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <IconButton
                  onClick={toggleMute}
                  sx={{
                    position: "absolute",
                    top: 20,
                    right: 20,
                    color: "white",
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Animation;
