import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Modal,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

// Import your images
import Automotive from "../assets/Portfolio/Automotive_630px.jpg";
import Radianture from "../assets/Portfolio/Showreel_Thumbnail.jpg";
import TVC from "../assets/Portfolio/TVC_630px.jpg";
import Archviz from "../assets/Portfolio/ArchViz_630px.jpg";
import Electronics from "../assets/Portfolio/Electronics_630px.jpg";
import Fashion from "../assets/Portfolio/Fashion_N_Luxury_630px.jpg";
import Furniture from "../assets/Portfolio/Furniture_630px.jpg";

const videoData = [
  {
    id: 1,
    thumbnail: Radianture,
    videoUrl:
      "https://player.vimeo.com/video/991563258?h=5ed328f8fd&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Radianture Showreel",
  },
  {
    id: 2,
    thumbnail: TVC,
    videoUrl:
      "https://player.vimeo.com/video/984122953?h=6f2141880f&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "TVC Showreel",
  },
  // Add remaining videos...
  {
    id: 3,
    thumbnail: Archviz,
    videoUrl:
      "https://player.vimeo.com/video/954733607?h=03103636a8&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Archviz Showreel",
  },
  {
    id: 4,
    thumbnail: Automotive,
    videoUrl:
      "https://player.vimeo.com/video/981334451?h=7dea40a6cf&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Automotive Showreel",
  },
  {
    id: 5,
    thumbnail: Electronics,
    videoUrl:
      "https://player.vimeo.com/video/981334533?h=39464a4391&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Electronics Showreel",
  },
  {
    id: 6,
    thumbnail: Fashion,
    videoUrl:
      "https://player.vimeo.com/video/981334319?h=25493686a9&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Fashion & Luxury Showreel",
  },
  {
    id: 7,
    thumbnail: Furniture,
    videoUrl:
      "https://player.vimeo.com/video/954733490?h=880a964a92&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    title: "Furniture Showreel",
  },
];

const ServiceCard = styled(Card)(({ theme }) => ({
  position: "relative",
  color: theme.palette.common.white,
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    opacity: 1,
  },
});

const PrivatePortfolio = () => {
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const handleOpen = (video) => {
    setCurrentVideo(video);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentVideo("");
    setOpen(false);
  };

  return (
    <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
      <Container maxWidth="lg" style={{ minHeight: "300px" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Portfolio
        </Typography>
        <Grid container spacing={3}>
          {videoData.map((video) => (
            <Grid item xs={12} sm={6} md={4} key={video.id}>
              <ServiceCard onClick={() => handleOpen(video)}>
                <CardMedia
                  component="img"
                  height="100%"
                  image={video.thumbnail}
                  sx={{
                    borderRadius: "8px",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
                <Overlay>
                  <PlayArrowIcon
                    sx={{ fontSize: 60, color: "#fff", opacity: 0.8 }}
                  />
                </Overlay>
                <CardContent
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "rgba(0, 0, 0, 0.6)",
                    color: "#fff",
                    textAlign: "center",
                    padding: "8px !important",
                  }}
                >
                  <Typography variant="h6">{video.title}</Typography>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "80%",
              height: "80%",
              maxWidth: 800,
              bgcolor: "transparent",
              boxShadow: "none",
              "&:focus": { outline: "none" },
            }}
          >
            <iframe
              src={currentVideo.videoUrl}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 20,
                left: 0,
                width: "830px",
                height: "520px",
              }}
              title={currentVideo.title}
            ></iframe>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default PrivatePortfolio;
