// src/components/Portfolio.js

import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async";

import image0 from "../assets/CGI_Imagery/Imagery_00.jpg";
import image1 from "../assets/CGI_Imagery/Imagery_001.jpg";
import image2 from "../assets/CGI_Imagery/Imagery_002.jpg";
import image3 from "../assets/CGI_Imagery/Imagery_003.jpg";
import image4 from "../assets/CGI_Imagery/Imagery_004.jpg";
import image5 from "../assets/CGI_Imagery/Imagery_005.jpg";
import image6 from "../assets/CGI_Imagery/Imagery_006.jpg";
import image7 from "../assets/CGI_Imagery/Imagery_007.jpg";
import image8 from "../assets/CGI_Imagery/Imagery_008.jpg";
import image9 from "../assets/CGI_Imagery/Imagery_009.jpg";
import image10 from "../assets/CGI_Imagery/Imagery_010.jpg";
import image11 from "../assets/CGI_Imagery/Imagery_011.jpg";
import image12 from "../assets/CGI_Imagery/Imagery_012.jpg";
import image13 from "../assets/CGI_Imagery/Imagery_013.jpg";
import image14 from "../assets/CGI_Imagery/Imagery_014.jpg";
import image15 from "../assets/CGI_Imagery/Imagery_015.jpg";
import image16 from "../assets/CGI_Imagery/Imagery_016.jpg";
import image17 from "../assets/CGI_Imagery/Imagery_017.jpg";
import image18 from "../assets/CGI_Imagery/Imagery_018.jpg";
import image19 from "../assets/CGI_Imagery/Imagery_019.jpg";

const images = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
]; // Array of image sources

const Portfolio = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Helmet>
        <title>Portfolio | Radianture</title>
        <meta
          name="description"
          content="Radianture is a global creative and tech agency that enhances product experiences through cutting-edge 3D content creation, visual effects, and automation solutions, empowering diverse industries to optimize performance and deliver rich, detailed customer-facing experiences."
        />
        <meta
          name="keywords"
          content="Radianture, cg imagery, animation, game art, vfx, augmented reality, virtual reality, real time configurator"
        />
        <meta property="og:title" content="Home Page | Radianture" />
        <meta
          property="og:description"
          content="Radianture is a global creative and tech agency that enhances product experiences through cutting-edge 3D content creation, visual effects, and automation solutions, empowering diverse industries to optimize performance and deliver rich, detailed customer-facing experiences."
        />
        <meta property="og:url" content="https://www.radianture.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container maxWidth="lg" style={{ minHeight: "300px" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Portfolio
          </Typography>
          <br />
          <br />
          <Typography variant="body1" paragraph>
            Thank you for your interest in Radianture. To request our portfolio,
            please email us at{" "}
            <a href="mailto:contact@radianture.com">contact@radianture.com</a>
          </Typography>

          <Grid item xs={12} md={6}>
            <Slider {...settings}>
              {images.map((src, index) => (
                <Box
                  key={index}
                  component="img"
                  sx={{
                    width: "100%",
                    height: "auto",
                    boxShadow: 3,
                  }}
                  alt={`Slide ${index + 1}`}
                  src={src}
                />
              ))}
            </Slider>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Portfolio;
