// src/components/Contact.js

import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import Benefits from "./Benefits";
import { handleContextMenu } from "../utils";
import Laptop_03_01 from "../assets/Real-Time Configurator/Laptop 03_01.jpg";
import Laptop_02 from "../assets/Real-Time Configurator/LapTop 02.png";
import Apple_Ipad_Air_01 from "../assets/Real-Time Configurator/Apple Ipad Air_01.png";
import Mobile_03 from "../assets/Real-Time Configurator/Mobile_03.png";
import IPad_02_02 from "../assets/Real-Time Configurator/IPad_02_02.jpg";
import real_time_video from "../assets/Real-Time Configurator UI.mp4";
import { Helmet } from "react-helmet-async";

const RealTimeConfigurator = () => {
  return (
    <>
      <Helmet>
        <title>Real time configurator</title>
        <meta
          name="description"
          content="Our custom-engineered WebGL 3D engine provide a cutting-edge solution for interactive product demos, allowing users to explore and customize products in real-time with realistic visualizations from multiple angles."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          py: 8,
        }}
      >
        <Container>
          <Typography variant="h4" component="h1" gutterBottom>
            Real-Time Configurator
          </Typography>
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt="Laptop"
            src={Laptop_03_01}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Typography variant="body1" paragraph>
            Our custom-engineered WebGL 3D engine for real-time product
            visualization, combined with bespoke data handling software tools,
            offers a state-of-the-art solution for interactive product demos,
            custom selling tools and 3D product configurators. The speed and
            efficiency of our technology enable unprecedented ways to sell
            complementary products and accessories. Customers can research,
            configure and purchase multiple products within virtual environments
            tailored to their specific use cases.
          </Typography>
          <br />
          <br />
          <br />
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt="Laptop"
            src={Laptop_02}
          />
          <br />
          <br />
          <br />
          <Typography variant="body1" paragraph>
            Users can view selected modifications from multiple angles in
            real-time, providing a realistic representation of their product.
            <br />
            By leveraging advanced 3D rendering technology, users can experience
            realistic visualizations of their personalized products.
          </Typography>
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item>
              <Box
                component="img"
                sx={{
                  width: 720, // Fixed width of 800px
                  height: "auto",
                }}
                alt="Example"
                src={Apple_Ipad_Air_01}
              />
            </Grid>
            <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component="img"
                sx={{
                  width: 260, // Fixed width of 800px
                  height: "auto",
                }}
                src={Mobile_03}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Typography variant="body1" paragraph>
            Bring the configurator experience with you anywhere, enabling users
            to effortlessly customize their personalized masterpieces on the go.
            <br />
            <br />
            Users can explore a vast array of options, including various models,
            colors and accessories available for their product.
          </Typography>
          <br />
          <Box
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt="Laptop"
          >
            <video
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              }}
              onContextMenu={handleContextMenu}
            >
              <source src={real_time_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Benefits />
          <br />
          <br />
          <br />

          <Box
            component="img"
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt="Laptop"
            src={IPad_02_02}
          />
        </Container>
      </Box>
    </>
  );
};

export default RealTimeConfigurator;
