// src/components/CgiImagery.js

import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import image0 from "../assets/VFX_960px.jpg";
import { Helmet } from "react-helmet-async";

const Vfx = () => {
  return (
    <>
      <Helmet>
        <title>VFX</title>
        <meta
          name="description"
          content="Discover the wide range of services offered by Radianture."
        />
        <meta
          name="keywords"
          content="Radianture, Services, CGI Imagery, Animation, Game Art"
        />
      </Helmet>
      <Box sx={{ bgcolor: "background.default", color: "text.primary", py: 8 }}>
        <Container
          maxWidth="lg"
          style={{ borderBottom: "0.2px solid #efefef" }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                VFX
              </Typography>
              <br />
              <Typography variant="body1" paragraph>
                At Radianture, our VFX services revolutionize visual
                storytelling with cutting-edge effects designed to captivate and
                mesmerize audiences. Specializing in creating stunning visual
                effects, we enhance the visual appeal of projects across various
                mediums, from blockbuster films to compelling advertisements and
                engaging digital media content. Our VFX solutions add a new
                dimension of creativity and sophistication, making a lasting
                impact and leaving audiences in awe. Our team of experts brings
                meticulous attention to detail and unparalleled creative
                expertise to every project, ensuring perfect execution in each
                frame. By seamlessly integrating CGI and other visual effects,
                we create immersive and believable experiences that elevate
                production quality. Radianture's commitment to innovation and
                excellence sets us apart in the VFX industry, leveraging the
                latest technologies and techniques to push the boundaries of
                what is possible. Partner with Radianture to transform your
                visual storytelling with effects that enrich narratives and
                resonate deeply with your audience.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                alt="VFX"
                src={image0}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Vfx;
