import React from "react";

import Hero from "../components/Hero";
import Services from "../components/Services";
import Contact from "../components/Contact";
import AboutUs from "../components/AboutUs";
import { Helmet } from "react-helmet-async";
const Home = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Services />
      <Contact />
      <Helmet>
        <title>Home Page | Radianture</title>
        <meta
          name="description"
          content="Radianture is a global creative and tech agency that enhances product experiences through cutting-edge 3D content creation, visual effects, and automation solutions, empowering diverse industries to optimize performance and deliver rich, detailed customer-facing experiences."
        />
        <meta
          name="keywords"
          content="Radianture, cg imagery, animation, game art, vfx, augmented reality, virtual reality, real time configurator"
        />
        <meta property="og:title" content="Home Page | Radianture" />
        <meta
          property="og:description"
          content="Radianture is a global creative and tech agency that enhances product experiences through cutting-edge 3D content creation, visual effects, and automation solutions, empowering diverse industries to optimize performance and deliver rich, detailed customer-facing experiences."
        />
        <meta property="og:url" content="https://www.radianture.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
    </>
  );
};

export default Home;
